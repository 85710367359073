.dropzone {
  border: 1px dashed #787878;
  width: 100%;
  border-radius: 20px;
  background: #f5f5f5;
  padding: 50px 0px 20px;
}
.dropzone span {
  color: rgb(240, 153, 141);
}
.inputUpload {
  display: none;
}
.lableUpload {
  color: rgb(240, 153, 141);
  text-decoration: underline;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Gilroy-SemiBold';
  margin-left: 5px;
  cursor: pointer;
}
.MuiFormControlLabel-label {
  font-size: 16px;
  line-height: 26px;
  color: #272e5f;
  font-family: 'Gilroy-SemiBold';
  padding-left: 10px;
}
.MuiSlider-valueLabelLabel {
  color: #fff !important;
}
.MuiSlider-valueLabel {
  top: -48px !important;
}
.colorPicker {
  width: 100% !important;
}
.w-color-saturation {
  border-radius: 0 !important;
  height: 350px !important;
}
.reponsive .react-colorful {
  width: 100%;
  height: 350px;
}
.w-color-alpha {
  height: 15px !important;
  margin-top: 5px;
}
.react-colorful__saturation {
  margin-bottom: 10px !important;
  border-radius: 0px !important;
}
.react-colorful__hue,
.react-colorful__alpha {
  margin-bottom: 10px !important;
  border-radius: 20px !important;
}
.color-alpha {
  height: 15px !important;
  margin-top: 5px;
}
.w-color-alpha div {
  border-radius: 50px !important;
}
.w-color-interactive div {
  height: 15px !important;
  width: 15px !important;
  border: 1px solid #fff !important;
}
.linkLogin{
  text-decoration: none;
  font-size: 16px;
  line-height: 150%;
  color: #F0998D;
}
.linkLogin:hover{
  color: #f57362;
}
.inputColor {
  /* border: none; */
  color: rgba(0, 0, 0, 0.8);
  font-size: 11px;
  text-transform: uppercase;
  margin: 0 auto;
  /* border: 1px solid #E1E1E1; */
  border-radius: 10px;
  padding: 10px;
  width: 100%;
}
.inputColor:focus-visible {
  border: none;
  outline: none;
}
@media (max-width: 1385px) {
  .w-color-saturation {
    height: 250px !important;
  }
}
@media (max-width: 800px) {
  .w-color-saturation {
    height: 350px !important;
  }
}
.MuiFormControlLabel-label {
  padding-left: 0px !important;
}
.radio {
  color: red;
}
