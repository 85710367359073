// paper & background
$paper: #ffffff;

// primary
$primaryLight: #eceff1;
$primaryMain: #f0998d;
$primaryDark: #546e7a;
$primary200: #b0bec5;
$primary800: #455a64;

// secondary
$secondaryLight: #ffe0dc;
$secondaryMain: #f0998d;
$secondaryDark: #f57362;
$secondary200: #f0998d;
$secondary800: #f18e80;

// success Colors
$successLight: #edf7ed;
$success200: #b6e0b3;
$successMain: #6cc067;
$successDark: #64ba5f;

// error
$errorLight: #e48784;
$errorMain: #d9534f;
$errorDark: #d54c48;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fdf5ea;
$warningMain: #f0ad4e;
$warningDark: #ec9c3d;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #212121;

//-----------------------|| DARK THEME VARIANTS ||-----------------------//

// paper & background
$darkBackground: #0e1b23; // level 3
$darkPaper: #060d12; // level 4

// dark 800 & 900
$darkLevel1: #0b161d; // level 1
$darkLevel2: #14252f; // level 2

// primary dark
$darkPrimaryLight: #eceff1;
$darkPrimaryMain: #78919c;
$darkPrimaryDark: #587583;
$darkPrimary200: #b0bec5;
$darkPrimary800: #44606e;

// secondary dark
$darkSecondaryLight: #e0f2f1;
$darkSecondaryMain: #009688;
$darkSecondaryDark: #00897b;
$darkSecondary200: #80cbc4;
$darkSecondary800: #00695c;

// text variants
$darkTextTitle: #e4e8f7;
$darkTextPrimary: #d5d9e9;
$darkTextSecondary: #d8ddf0;

//-----------------------|| JAVASCRIPT ||-----------------------//

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;

  //-----------------------|| DARK THEME VARIANTS ||-----------------------//

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;
}
