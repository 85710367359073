// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

//-----------------------|| APEXCHART ||-----------------------//

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

//-----------------------|| PERFECT SCROLLBAR ||-----------------------//

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}
.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}
.styles-module_slide__1zrfk, .styles-module_wrapper__1I_qj{
    height: 100vh !important;
}
.dropzon{
    border-radius: 5px;
    border: 1px dashed rgba(0, 0, 0, 0.38);
    margin: auto;
    padding: 30px 20px;
    text-align: center;
    cursor: pointer;
}

//-----------------------|| ANIMATION KEYFRAMES ||-----------------------//

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}
@font-face {
    font-family: Gilroy-Bold;
    src: url('../fonts/Gilroy-Bold.otf') format('truetype');
    font-weight: 700 800 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: Gilroy-Medium;
    src: url('../fonts/Gilroy-Medium.ttf') format('truetype');
    font-weight: 500 medium;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: Gilroy-SemiBold;
    src: url('../fonts/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600 semibold;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: Gilroy;
    src: url('../fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: 400 normal;
    font-style: normal;
    font-display: swap;
  }
  .MuiInput-input::placeholder{
      color: #717A90 !important;
      opacity: 1;
  }
  .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop, .css-sox5kk-MuiBackdrop-root{
      background-color: #303778;
      opacity: 0.7 !important;
  }

  .Carousel-buttonHidden-33, .Carousel-buttonHidden-34 {
    opacity: 1 !important;
}
#content > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-9.css-u5cfib-MuiGrid-root > div > div > div:nth-child(4) > form > div:nth-child(1) > div.MuiBox-root.css-jt94au > div,
#content > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-9.css-u5cfib-MuiGrid-root > div > div > div:nth-child(4) > form > div:nth-child(1) > div.MuiInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.makeStyles-root-39.css-1bjjtdx-MuiInputBase-root-MuiInput-root{
    border: none !important;
    background-color: transparent !important;
    padding: 0 !important;
}
 .css-i4bv87-MuiSvgIcon-root{
     fill: #303778;
 }
 .fullWidth{
     width: 100%;
     color: #303778 !important;
     font-size: 14px !important;
     padding: 15px 0 15px 10px !important;
 }
.MuiFormHelper{
    display: none !important;
}